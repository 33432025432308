/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-purple/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import 'primeflex/primeflex.css';
@import 'quill/dist/quill.snow.css';
/*@import "primeflex/themes/arya-blue.css";*/

@import "./assets/scss/app.scss";

html,
body {
    font-size: 12px;
}

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.table th,
.table td {
    cursor: pointer;
    font-size: 12px;
}

.btn-danger-light {
    background-color: #fce6e7 !important;
    color: #000;
}

.btn-success-light {
    background-color: #e4f2e4 !important;
    color: #000;
}

.cursor-pointer {
    cursor: pointer;
}

.dropdown .dropdown-menu {
    min-width: auto;
    z-index: 9;
    transform: translate(-30px, 35px)!important;
}

.shadow {
    box-shadow: none;
}

.scroll {
    overflow: auto;
}

.copy-text {
    white-space: nowrap;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 12px;
}

.copy-text:hover {
    text-decoration: underline;
}

.nav-tabs {
    background-color: #f1f0ff;
}

.loader-box {
    height: auto;
}

.loader-box .loader-2 {
    border-left-color: #fff;
    border-right-color: #fff;
}

.loader-box [class*=loader-] {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: inherit;
    vertical-align: middle;
    margin-right: 10px;
}


.filter-footer {
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 23%;
    height: 8vh;
    padding: 10px 0;
    box-shadow: 0px -4px 6px -6px #000;
}

.p-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
}

.p-image img {
    width: 90%;
    margin: auto;
}


.activity-modal
{
  width: 100rem;
}

p-dropdown {
  .ui-dropdown {
    min-width:0; // auto , initial 😀
  }
}
